// Import packages
import React from "react";

// Import components
import Hero from "../components/Hero";
import Section from "../components/Section";
import View from "../components/View";

// Define component
function Privacy() {
  return (
    <View>
      <Hero imageSrc="architectural-design-architecture-building-business-443383.jpg">
        <h2>Press</h2>
        <p>Information for press inquiries</p>
      </Hero>
      <Section>
        <h3>Download press kit</h3>
        <p>
          Please download our press kit for press photos and information about
          Lorenz.
        </p>
        <p>
          <a href="presskit-20250124.zip">Download press kit</a>
        </p>
        <h3>Press inquiries</h3>
        <p>
          If you have any questions, or require more information about Lorenz or
          our investments, please do not hesitate to contact us at{" "}
          <a href="mailto:hello@lorenzinvest.com">hello@lorenzinvest.com</a>.
        </p>
      </Section>
    </View>
  );
}

// Export component
export default Privacy;
